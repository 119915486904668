import { render, staticRenderFns } from "./shouContact.vue?vue&type=template&id=b46e9cc6&scoped=true&"
import script from "./shouContact.vue?vue&type=script&lang=js&"
export * from "./shouContact.vue?vue&type=script&lang=js&"
import style0 from "@/styles/pc/home.less?vue&type=style&index=0&id=b46e9cc6&lang=less&scoped=true&"
import style1 from "@/styles/pc/homewe.less?vue&type=style&index=1&id=b46e9cc6&lang=less&scoped=true&"
import style2 from "@/styles/phone/shouhome.less?vue&type=style&index=2&id=b46e9cc6&lang=less&scoped=true&"
import style3 from "@/styles/phone/shouAbout.less?vue&type=style&index=3&id=b46e9cc6&lang=less&scoped=true&"
import style4 from "@/styles/phone/shouContact.less?vue&type=style&index=4&id=b46e9cc6&lang=less&scoped=true&"
import style5 from "./shouContact.vue?vue&type=style&index=5&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b46e9cc6",
  null
  
)

export default component.exports